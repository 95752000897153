import React, { Component } from 'react'
import { render } from 'react-dom'
import EmailEditor from 'react-email-editor'
import ReactDOM from 'react-dom'
import {

  Grid, Header, Button, Icon

} from 'semantic-ui-react';



var V1 = "ERES FEOOO";

//const CargarDesignEditorHTML = (instance, someData) => {
//}

 window.CargarDesignEditorHTML= function(data) {
	App.editor.loadDesign(JSON.parse(data));
 
}
class App extends Component {
 //CargarDesignEditorHTML = (someData) => { CargarDesignEditorHTML(this, someData); }
 // constructor(props) {
     //   super(props);
    // }
 // componentDidMount: function() {
  // var $this = $(ReactDOM.findDOMNode(this).getElementsByClassName('blockbuilder-branding'))
//var element = document.getElementsByClassName('blockbuilder-branding');
// <div>
     // <h1>react-email-editor Demo {this.props.v1}</h1>

    //  <div>
      //  <button onClick={this.exportHtml}>Exportar HTML</button>
      //</div>
   // <div>
      //  <button onClick={this.saveDesign}>Guardar</button>
     // </div>
  //}


  render() {
 
 
 
 
    return  <EmailEditor
        ref={editor => this.editor = editor}
		    onLoad={this.onLoad}
        onDesignLoad={this.onDesignLoad}
		selectImage={this.selectImage}
		 options={{
    locale: 'es-ES'
  }}  />
  
   // </div>
	 // const node = ReactDOM.findDOMNode(this.editor).getElementsByClassName('blockbuilder-branding')
  //console.log(node);
  }

  
   onLoad = () => {
   //console.log('OKK')
   window.OnLoadEditorHTML();
  // $('.blockbuilder-branding').css("display","none")
    // this.editor.addEventListener('onDesignLoad', this.onDesignLoad)
   // this.editor.loadDesign(sample)
  }
  
  onDesignLoad = (data) => {
   // console.log('onDesignLoad', data)
		  window.OnLoadContenidoEditorHTML(data); 
  }
  
  
  

  saveDesign = () => {
    this.editor.saveDesign(design => {
	
	//window.OnGuardarHTML(design);
	
      //console.log('saveDesign', design)
    //  alert("Design JSON has been logged in your developer console.")
    })
  }
  selectImage = () => {
    this.editor.selectImage(data => {
	//alert("SE SUBE IMAGEN")
    })
  }
  exportHtml = () => {
    this.editor.exportHtml(data => {
	  const { design, html } = data
	//window.OnExportarEditorHTML(data);
	 // console.log('design',design)
     // console.log('exportHtml', html)
     // alert("Output HTML has been logged in your developer console.")
    })
  }

  
  
}
window.IniciarEditorHTML = function(v1, v2) {

    ReactDOM.render(<App v1={v1} v2={v2} />, document.getElementById('root'))
}

//render(<App  />, document.getElementById('root'))

export default App;
